<template>
  <v-footer
    v-if="this.$vuetify.breakpoint.smAndUp"
    class="footer-class"
    color="#FFFFFF"
  >
    <!--inicio -->

    <v-layout column wrap>
      <v-layout row wrap style="padding: 5vh 5vw">
        <!--inicio -->
        <v-flex sm4 style="padding: 16px; padding-left: 0%">
          <v-layout wrap column>
            <v-flex>
              <v-img src="/static/logo_stc.png" width="300"></v-img>
            </v-flex>
            <v-flex style="color: #696559; font-size: 16px">
              <p>
                Somos una empresa de Transporte dedicada a la operación de
                unidades de transporte y materiales con un alto enfoque al
                cuidado del medio ambiente.
              </p>
            </v-flex>
          </v-layout>
        </v-flex>

        <!-- Mapa de sitio -->

        <v-flex sm3 pa-4>
          <v-layout column wrap>
            <p style="color: #696569; letter-spacing: 7.2px">MAPA DE SITIO</p>
            <v-flex>
              <p style=" cursor: pointer;" @click.prevent="$router.push('/') ">Inicio</p>
            </v-flex>
            <v-flex>
              <p style=" cursor: pointer;" @click.prevent="$router.push('/transportes') ">STC Transportes</p>
            </v-flex>
            <v-flex>
              <p style=" cursor: pointer;" @click.prevent="$router.push('/reciclaje') ">
                STC Reciclaje
              </p>
            </v-flex>
            <v-flex>
              <p style=" cursor: pointer;" @click.prevent="$router.push('/confinamiento') ">STC Confinamiento</p>
            </v-flex>
            <v-flex>
              <p style=" cursor: pointer;" @click.prevent="$router.push('/faq') ">
                Preguntas frecuentes
              </p>
            </v-flex>
            <v-flex>
              <p style=" cursor: pointer;" @click.prevent="$router.push('/contact') ">Contacto</p>
            </v-flex>
          </v-layout>
        </v-flex>

        <!-- Redes sociales -->

        <v-flex sm4 pa-4>
          <v-layout column wrap>
            <v-flex>
              <v-layout row wrap>
                <v-flex sm12>
                  <p style="color: #696569; letter-spacing: 7.2px">
                    INFORMACIÓN
                  </p>
                </v-flex>
                <v-flex sm12>
                  <p>
                    Servicio de Transporte y Construcción de Coahuila, .S.A De
                    C.V. Carretera 57 km 178.1 
                                <p>Col. California Castaños Coahuila. C.P. 25870</p>

                  </p>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex>
              <v-layout row wrap>
                <v-flex sm12>
                  <p>Tel. 86 6643 2551</p>
                </v-flex>
              </v-layout>
            </v-flex>
            <!-- 
            <v-flex>
              <v-layout row wrap>
                <v-flex sm12>
                  <p style="color: #696569; letter-spacing: 7.2px">
                    SOCIAL MEDIA
                  </p>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex>
              <v-layout row wrap style="margin-top: 20px">
                <v-btn
                  v-for="(social, index) in networks"
                  :key="index"
                  :href="social.url"
                  text
                >
                  <img class="footer-net" :src="social.img" alt />
                </v-btn>
              </v-layout>
            </v-flex>-->
          </v-layout>
        </v-flex>

        <!-- Fin de redes -->
      </v-layout>
      <v-divider></v-divider>
      <v-container>
        <v-layout row wrap align-center>
          <v-flex xs2> </v-flex>
          <v-flex xs4>
            <a
              style="cursor: pointer"
              target="_blank"
              href="https://sofex.com.mx/"
              >Powered By Sofex Technologies</a
            >
          </v-flex>
          <v-flex xs2> </v-flex>
          <v-flex xs4>
            <span
              >All Rights Reserved
              <a
                >GRUPO STC</a
              ></span
            >
          </v-flex>
          <v-flex xs1> </v-flex>
        </v-layout>
      </v-container>
    </v-layout>
  </v-footer>

  <!-- footer -->

  <v-footer v-else class="footer-class" color="#F2F3FF">
    <v-container>
      <v-layout column>
        <v-flex>
          <v-img src="/static/logo_stc.png"></v-img>
        </v-flex>
        <v-flex>
          <v-img
            style="height: 30px; width: 30px; margin: 0 auto"
            src="/static/location.svg"
          ></v-img>
        </v-flex>
        <v-flex>
          <p style="text-align: center">
            Servicio de Transporte y Construcción de Coahuila, .S.A De C.V. Carretera 57 km 178.1 Col. California Castaños Coahuila. C.P. 25870
          </p>
        </v-flex>
        <v-flex sm1>
          <v-img
            style="height: 20px; width: 20px; margin: 0 auto"
            src="/static/phone.svg"
          ></v-img>
        </v-flex>
        <v-flex sm11>
          <p style="text-align: center">+52 86 6643 2551</p>
        </v-flex>
        <v-flex>
          <p style="text-align: center">
            Inicio
          </p>
        </v-flex>
        <v-flex>
          <p style="text-align: center">
            ¿Quiénes somos?
          </p>
        </v-flex>
        <v-flex>
          <p style="text-align: center">
            STC Transportes
          </p>
        </v-flex>
        <v-flex>
          <p style="text-align: center">
            STC Reciclaje
          </p>
        </v-flex>
                <v-flex>
          <p style="text-align: center">
            Preguntas frecuentes
          </p>
        </v-flex>
        <v-flex>
          <p style="text-align: center">
            {{ language == 0 ? "Contacto" : "Contact" }}
          </p>
        </v-flex>
        <!-- 
        <v-flex>
          <v-layout
            row
            wrap
            style="
              margin-top: 20px;
              align-items: center;
              justify-content: center;
            "
          >
            <v-btn
              v-for="(social, index) in networks"
              :key="index"
              :href="social.url"
              text
            >
              <img class="footer-net" :src="social.img" alt />
            </v-btn>
          </v-layout>
        </v-flex>
        -->
        <v-flex>
          <v-divider style="margin: 30px 0"></v-divider>
        </v-flex>
        <v-flex>
          <p style="text-align: center">All Rights Reserved GRUPO STC</p>
        </v-flex>
      </v-layout>
    </v-container>
  </v-footer>
</template>
<script>
// Utilities
import { mapMutations } from "vuex";

export default {
  data: () => ({
    contact: {
      name: "",
      mail: "",
      phone: "",
      comment: "",
    },
    information: [],
    e1: 1,
    networks: [
      {
        img: "/static/instagram.svg",
        url: "https://www.instagram.com/?hl=en",
      },

      {
        img: "/static/facebook.svg",
        url: "https://www.facebook.com/?ref=br_rs",
      },
    ],
  }),
  beforeMount() {
    // toggle the VueX lang state
    this.$store.commit("toggle_language", localStorage.getItem("lang"));
  },
  mounted() {
    this.getText();
  },
  methods: {
    getText() {
      var axios = require("axios");

      const that = this;

      var config = {
        method: "get",
        url:
          "http://ec2-107-23-108-138.compute-1.amazonaws.com/api/information/",
        headers: {},
      };

      axios(config)
        .then(function (response) {
          that.information = response.data.information;
          (that.networks[0].url =
            response.data.information.spanish.footer.instagramLink),
            (that.networks[1].url =
              response.data.information.spanish.footer.facebookLink),
            that.$forceUpdate();
        })
        .catch(function (error) {});
    },
    toggle_language(value) {
      this.$store.commit("toggle_language", value);
    },
    ...mapMutations(["toggleDrawer"]),
    onScroll() {
      this.isScrolling =
        (window.pageYOffset || document.documentElement.scrollTop || 0) > 600;
    },
    burgerClick() {
      this.showOptions = !this.showOptions;
    },
  },
  computed: {
    language() {
      // iniciatlize global language for this section ( 0 = spanish, 1 = english)
      return this.$store.state.language;
    },
  },
};
</script>
<style>
.toolbar-btn {
  font-family: "Helvetica";
  color: white !important;
  font-weight: bold;
}
.v-app-bar.v-app-bar--fixed {
  top: 0 !important;
}
.appbar-logo {
  margin-left: 5vw;
  max-width: 150px;
  margin-right: 35vw;
}
.footer-class p {
  color: #adafcb;
}
</style>
